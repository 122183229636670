<template>
  <v-container>
    <v-row>
      <v-col cols="4" sm="4" offset-sm="4" class="mt-10">
        <v-card>
          <v-row>
            <v-col cols="12" class="pb-0">
              <base-title :title="$t('feedbackTitle')" class="text-center" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn class="mx-3" :dark="selected !== 'positive'" color="primary" :disabled="selected !== 'none'"
                :depressed="selected === 'positive'" @click="voteUp">
                <v-icon>
                  mdi-thumb-up
                </v-icon>
              </v-btn>
              <v-btn class="mx-3" :dark="selected !== 'negative'" :disabled="selected !== 'none'"
                :depressed="selected === 'negative'" @click="voteDown">
                <v-icon>
                  mdi-thumb-down
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Feedback',

  props: {
    value: {
      type: String,
      default: () => "salvation"
    }
  },
  data() {
    return {
      selected: "none"
    }
  },
  methods: {
    voteUp(event) {
      this.$gtag.event("positive", {
        event_category: "feedback",
        event_label: this.value,
        value: 1
      });
      this.selected = "positive";
    },
    voteDown(event) {
      this.$gtag.event("negative", {
        event_category: "feedback",
        event_label: "negative",
        value: -1
      });
      this.selected = "negative";
    }
  }
}
</script>
